<template src="./HealthCenters.html">
   
</template>

<script>

    export default {
        name: 'HealthCenters',

        computed: {
            healthCenters() {
                return this.$store.getters['lookup/getHealthCenterByMunicipality'](this.municipalityId)
            },

            municipalities() {
                return this.$store.state.lookup.municipalities
            },
        },

        created() {
            this.getLookups();
        },

        data: () => ({
            municipalityId: 15,
            //healthCenters: []
        }),

        methods: {

            openGoogleMaps(healthCenter) {
                var href = `https://www.google.com/maps/search/?api=1&query=${healthCenter.latitude},${healthCenter.longitude}`;
                window.open(href, '_blank');
            },

            getLookups() {
                this.$store.dispatch("lookup/getHealthCenters");
                this.$store.dispatch("lookup/getMunicipalities");
            }
        }
    };
</script>

<style lang="scss" scoped src="./HealthCenters.scss" ></style>


